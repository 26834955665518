/**
 * Helper functions for use with the Marketo Forms API.
 * https://developers.marketo.com/javascript-api/forms/api-reference/
 */

import {
  MARKETO_PARAMS_EXPIRE_AT_KEY,
  VERBOSE_MARKETO_PARAMS,
} from "@10x/site/utils/local-storage-keys";
import { format, isBefore } from "date-fns";
import * as Yup from "yup";

import { THIS_FIELD_IS_REQUIRED } from "./constants";
import type { LpFormFieldResponse, MarketoFormResponse } from "./types";

/**
 * Returns a Marketo-friendly set of form values.
 */
export const getCleanData = (values: Record<string, any>) => {
  const cleanValues = { ...values };

  /**
   * Formik sends radio values in an array.
   * We need to pull the value out.
   */
  if (
    cleanValues["collectionConsent"] &&
    Array.isArray(cleanValues["collectionConsent"])
  ) {
    cleanValues["collectionConsent"] = cleanValues["collectionConsent"].pop();
  }

  return cleanValues;
};

/**
 * Returns dependent select options based on a parent field.
 */
export const getDependentOptions = (
  fields: Record<string, Field>,
  parent: string,
  field: string,
) => {
  const { rules } = fields[field]?.visibilityRules || {};
  return rules && rules.length
    ? rules
        .filter((rule) => rule.values.includes(parent))
        .map((rule) => rule.picklistFilterValues)
        .reduce(
          (accumulator, currentValue) => [...accumulator, ...currentValue],
          [],
        )
    : [];
};

/**
 * Inspects a row array and returns a dictionary of the same,
 * keyed on field name for faster and easier lookup.
 */
export const getFields = (rows: Array<LpFormFieldResponse>) => {
  return rows.reduce((accumulator, currentValue) => {
    accumulator[currentValue.id] = currentValue;
    return accumulator;
  }, {} as Record<string, any>);
};

/**
 * Returns a Marketo form massaged for use with React/Formik.
 */
export const getFormikFromMarketo = (
  form: MarketoFormResponse,
  passedValues: Record<string, any>,
) => {
  const { rows } = form;
  const fields = getFields(rows);
  const trackerValues = getTrackerValues();
  const initialValues = getInitialValues(
    rows,
    passedValues,
    Object.fromEntries(trackerValues),
  );
  const validationSchema = getValidationSchema(fields);
  const hiddenFields = getHiddenFields(rows, {
    ...initialValues,
    ...passedValues,
  });

  return {
    fields,
    formId: form.id,
    getDependentOptions,
    getVisibility,
    hiddenFields,
    initialValues,
    submitMarketoForm,
    trackerValues,
    validationSchema,
  };
};

/**
 * Returns Marketo tracking values from local storage, if any.
 */
export const getTrackerValues = () => {
  const expireAtString = localStorage.getItem(MARKETO_PARAMS_EXPIRE_AT_KEY);

  const trackerValues = new Map();

  if (!expireAtString) {
    return trackerValues;
  }

  const expireAt = new Date(expireAtString);

  if (isBefore(expireAt, new Date())) {
    return trackerValues;
  }

  VERBOSE_MARKETO_PARAMS.forEach((key) => {
    const value = localStorage.getItem(key);
    trackerValues.set(key, value);
  });

  return trackerValues;
};

/**
 * Returns a Formik initial values object.
 */
export const getInitialValues = (
  rows: any[],
  passedValues: Record<string, string>,
  trackerValues: Record<string, unknown>,
) => {
  const initialValues = rows.reduce((accumulator, currentValue) => {
    const { InputInitialValue, Name } = currentValue;

    if (InputInitialValue) {
      accumulator[Name] = InputInitialValue;
    }

    return accumulator;
  }, {} as Record<string, string>);

  // Overwrite initial value if one was passed imperatively
  if (passedValues) {
    Object.keys(passedValues).forEach((key) => {
      initialValues[key] = passedValues[key];
    });
  }

  // Required for form validation to work
  initialValues["temp10xProductsofInterest"] = "";
  initialValues["tempIndustry"] = "";

  /**
   * Add today's date in the nonstandard format that Marketo expects,
   * eg. May 04, 2020.
   */
  const today = format(new Date(), "MMM dd, yyyy");
  initialValues["Last_Quote_Contact_Request_Date__c"] = today;
  initialValues["Last_Quote_Request_Date__c"] = today;

  return {
    ...initialValues,

    // Overwrite any initial values with tracker values from local storage
    // if they exist
    ...trackerValues,
  };
};

/**
 * Returns a useable hash to both generate the required hidden fields in the dom,
 * and be regular values in Formik
 */

export function getHiddenFields(
  rows: LpFormFieldResponse[],
  fallbackValues: Record<string, any>,
) {
  const params: Record<string, any> = rows
    .filter((field) => field.dataType === "hidden")

    // Deprecated field, do not use
    .filter((field) => field.id !== "temp10xProductInterestAreas")

    // This field must be handled manually below
    .filter((field) => field.id !== "temp10xProductsofInterest")
    .reduce((hash, field) => {
      const value =
        fallbackValues[field.id] ||
        field.defaultValue ||
        field?.autoFill?.value;

      return { [field.id]: value, ...hash };
    }, {});

  // We have to explicitly set the product name as the product interest area
  params["temp10xProductsofInterest"] =
    fallbackValues["temp10xProductsofInterest"];

  return params;
}

/**
 * Returns a Formik validation schema based for
 * the passed fields.
 */
export const getValidationSchema = (fields: Record<string, Field>) => {
  // Returns dependent values based on the country field
  const getDependentValues = (field: string) => {
    const { rules } = fields[field].visibilityRules;
    return rules
      .map((rule) => rule.values)
      .reduce(
        (accumulator, currentValue) => [...accumulator, ...currentValue],
        [],
      );
  };

  // All the possible supported fields and their validations
  const validationFields = {
    City: Yup.string().when("Country", {
      is: (value: string) => getDependentValues("City").includes(value),
      then: (schema: Yup.StringSchema) =>
        schema.required(THIS_FIELD_IS_REQUIRED),
    }),
    Company: Yup.string().required(THIS_FIELD_IS_REQUIRED),
    Country: Yup.string().required(THIS_FIELD_IS_REQUIRED),
    Email: Yup.string().email("Invalid email").required(THIS_FIELD_IS_REQUIRED),
    FirstName: Yup.string().required(THIS_FIELD_IS_REQUIRED),
    LastName: Yup.string().required(THIS_FIELD_IS_REQUIRED),
    Other_Primary_Area_of_Research__c: Yup.string().when(
      "Primary_Area_of_Research__c",
      {
        is: (value: string) => value === "Other",
        then: (schema: Yup.StringSchema) =>
          schema.required(THIS_FIELD_IS_REQUIRED),
      },
    ),
    PostalCode: Yup.string().when("Country", {
      is: (value: string) => getDependentValues("PostalCode").includes(value),
      then: (schema: Yup.StringSchema) =>
        schema.required(THIS_FIELD_IS_REQUIRED),
    }),
    Primary_Area_of_Research__c: Yup.string().required(THIS_FIELD_IS_REQUIRED),
    State: Yup.string().when("Country", {
      is: (value: string) => getDependentValues("State").includes(value),
      then: (schema: Yup.StringSchema) =>
        schema.required(THIS_FIELD_IS_REQUIRED),
    }),
    // marketo sends this data as `['yes']`
    collectionConsent: Yup.array()
      .of(Yup.string())
      .required(THIS_FIELD_IS_REQUIRED),
    tempIndustry: Yup.string().required(THIS_FIELD_IS_REQUIRED),
  };

  const schema: Record<string, Yup.AnySchema> = {};

  Object.keys(fields).forEach((fieldName) => {
    if (validationFields[fieldName as keyof typeof validationFields]) {
      schema[fieldName] =
        validationFields[fieldName as keyof typeof validationFields];
    }
  });

  return Yup.object().shape(schema);
};

interface Field {
  visibilityRules: {
    rules: {
      picklistFilterValues: string[];
      values: string[];
    }[];
  };
}

/**
 * Returns a flag indicating if a dependent field should be visible.
 */
export const getVisibility = (
  fields: Record<string, Field>,
  parent: string,
  field: string,
) => {
  const { rules } = fields[field]?.visibilityRules || {};

  let isVisible = false;

  if (rules && rules.length) {
    for (const rule of rules) {
      if (rule.values.includes(parent)) {
        isVisible = true;
        break;
      }
    }
  }

  return isVisible;
};

/**
 * Abstracts away the dirty business of submitting
 * a Marketo form and handling the related responses.
 */
export const submitMarketoForm = (
  formId: number,
  data: Record<string, any>,
) => {
  return new Promise((resolve, reject) => {
    const script = document.createElement("script");

    script.onload = () => {
      const cleanData = getCleanData(data);

      // @ts-ignore
      MktoForms2.loadForm(
        process.env.NEXT_PUBLIC_MARKETO_CNAME as string,
        process.env.NEXT_PUBLIC_MARKETO_MUNCHKIN_ID as string,
        formId,
        // @ts-ignore
        (loadedForm: MarketoFormObject) => {
          // @ts-ignore
          loadedForm.onSuccess((form) => {
            resolve(form);

            // VERY IMPORTANT
            // Return false or MktoForms2 will try to redirect
            return false;
          });

          // @ts-ignore
          loadedForm.onValidate((isValid) => {
            if (!isValid) {
              reject({
                cleanData,
                message:
                  "The form could not be submitted due to a Marketo validation error.",
              });
            }
          });

          // @ts-ignore
          loadedForm.vals(cleanData);
          loadedForm.submit();
        },
      );
    };

    script.src = `${process.env.NEXT_PUBLIC_MARKETO_CNAME}/js/forms2/js/forms2.min.js`;
    document.body.appendChild(script);
  });
};
