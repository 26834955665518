import { css } from "@emotion/react";
import type { HTMLProps, VoidFunctionComponent } from "react";

interface Props
  extends Pick<
    HTMLProps<HTMLInputElement>,
    | "checked"
    | "defaultChecked"
    | "disabled"
    | "id"
    | "name"
    | "onBlur"
    | "onChange"
    | "type"
    | "value"
  > {}

// A hidden checkbox to be used when we want to substitute our own version
// for styling purposes
const HiddenInput: VoidFunctionComponent<Props> = ({
  checked,
  defaultChecked,
  disabled,
  id,
  name,
  onBlur,
  onChange,
  type,
  value,
}) => {
  return (
    <input
      checked={checked}
      css={css`
        /* Hide input but keep it visible to screen readers. */
        top: 0;
        left: 0;
        width: 100%;
        cursor: inherit;
        height: 100%;
        margin: 0;
        opacity: 0;
        padding: 0;
        z-index: 1;
        position: absolute;
        display: none;
        pointer-events: none;
      `}
      defaultChecked={defaultChecked}
      disabled={disabled}
      // A unique identifier that enables the input to
      // be associated with a label for accessibility.
      id={id}
      name={name}
      onBlur={onBlur}
      onChange={onChange}
      type={type}
      // Arbitrary value for submission when checked is true
      value={value}
    />
  );
};

export default HiddenInput;
